<style lang="scss">
@import "~scss/variables";
@import "~scss/mixins";
#dontGrow {
  flex-grow: 0 !important;
}
.form-input {
  &__count {
    font-size: fz(xs);
    color: $grey_mid_1;
  }

  input[type="text"],
  input[type="number"],
  input[type="date"],
  input[type="password"],
  input[type="email"],
  input[type="time"] {
    border: 0;
    padding: spacing(xxs) spacing(xs);
    background-color: white;
    &:not(.bare) {
      border: 1px solid $border_grey;
      &:focus {
        box-shadow: 0px 0px 5px #7fd7ff;
      }
    }

    &.full {
      width: 100%;
    }
  }

  label {
    padding-left: 0px;
  }

  .label--md {
    width: 100px;
  }

  .label--partner-form {
    width: 210px;
  }

  .required:after {
    color: #ff0000;
    content: " *";
    display: inline;
  }
}
</style>
<template>
  <div class="form-input" id="dontGrow">
    <label :for="id" v-if="label" :class="this.labelClass">{{label}}</label>
    <input
      :id="id"
      :class="{hasValue, bare, full}"
      :type="type ? type : 'text'"
      :value="value"
      :autofocus="autofocus"
      @input="onInput"
      @change="$emit('change', arguments[0].target.value)"
      :disabled="disabled"
      :max="max"
      :maxlength="maxlength"
      :min="min"
      :pattern="pattern"
      :placeholder="placeholder"
      :readonly="readonly"
      :required="required"
      :size="size"
      :step="step"
      :spellcheck="spellcheck"
      @focus="hasValue = ''"
      @blur="hasValue = value ? '' : ' empty'"
      @keypress="checkContraints"
      @keyup="onKeyUp"
    />
    <div class="form-input__count block-xs--xxs" v-if="maxlength || showCount">
      {{count}}
      <span v-if="maxlength">/{{maxlength}}</span>
    </div>
  </div>
</template>
<script>
export default {
    name: "FormInput",
    data: function() {
        return {
            id: `form-input-${Math.random()
                .toString(36)
                .substring(7)}`,
            hasValue: Boolean(this.value),
            count: this.value ? this.value.length : 0
        };
    },
    watch: {
        value(value) {
            if (value === "") {
                this.hasValue = false;
            } else {
                this.hasValue = true;
            }

            this.count = value.length;
        }
    },
    props: [
        "autofocus",
        "disabled",
        "label",
        "max",
        "maxlength",
        "min",
        "pattern",
        "placeholder",
        "readonly",
        "required",
        "size",
        "step",
        "type",
        "value",
        "showCount",
        "bare",
        "spellcheck",
        "full",
        "labelSize",
        "constrainingFunc",
        "keyUpFunc"
    ],
    computed: {
        labelClass() {
            const labelClass = this.labelSize
                ? `label--${this.labelSize}`
                : "label--md";
            const required = this.required ? this.required : false;
            let classes = {
                empty: this.hasValue,
                required: required
            };

            classes[labelClass] = true;

            return classes;
        }
    },
    methods: {
        onInput(e) {
            this.$emit("input", e.target.value);
        },
        onKeyUp(e) {
            if (this.keyUpFunc === undefined) return true;

            return this.keyUpFunc(e);
        },
        checkContraints(e) {
            if (this.constrainingFunc === undefined) return true;

            const event = e ? e : window.event;
            return this.constrainingFunc(event);
        }
    }
};
</script>
