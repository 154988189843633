var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "form-input", attrs: { id: "dontGrow" } }, [
    _vm.label
      ? _c("label", { class: this.labelClass, attrs: { for: _vm.id } }, [
          _vm._v(_vm._s(_vm.label)),
        ])
      : _vm._e(),
    _c("input", {
      class: { hasValue: _vm.hasValue, bare: _vm.bare, full: _vm.full },
      attrs: {
        id: _vm.id,
        type: _vm.type ? _vm.type : "text",
        autofocus: _vm.autofocus,
        disabled: _vm.disabled,
        max: _vm.max,
        maxlength: _vm.maxlength,
        min: _vm.min,
        pattern: _vm.pattern,
        placeholder: _vm.placeholder,
        readonly: _vm.readonly,
        required: _vm.required,
        size: _vm.size,
        step: _vm.step,
        spellcheck: _vm.spellcheck,
      },
      domProps: { value: _vm.value },
      on: {
        input: _vm.onInput,
        change: function ($event) {
          return _vm.$emit("change", arguments[0].target.value)
        },
        focus: function ($event) {
          _vm.hasValue = ""
        },
        blur: function ($event) {
          _vm.hasValue = _vm.value ? "" : " empty"
        },
        keypress: _vm.checkContraints,
        keyup: _vm.onKeyUp,
      },
    }),
    _vm.maxlength || _vm.showCount
      ? _c("div", { staticClass: "form-input__count block-xs--xxs" }, [
          _vm._v(" " + _vm._s(_vm.count) + " "),
          _vm.maxlength
            ? _c("span", [_vm._v("/" + _vm._s(_vm.maxlength))])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }