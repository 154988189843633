<template>
  <div class="login row no-gutters">
    <div class="col-md-4">
      <div class="login-form row no-gutters">
        <div class="login-form__logo-container col-12">
          <div class="login-form__logo" />
        </div>

        <form class="login-form__form shadow--1 col-12">
          <div class="login-form__title_bold">Reset Password</div>
          <div class="login-form__title">Enter the email address associated with your account.</div>

          <div class="login-form__input-container">
            <!-- <form-input
              v-model="invitationCode"
              class="login-form__input"
              type="text"
              placeholder="Invitation Code"
              autofocus
            /> -->
            <form-input v-model="email" class="login-form__input" type="email" placeholder="Email" />
            <!-- <form-input
              v-model="password"
              class="login-form__input"
              type="password"
              placeholder="New Password"
            />
                        <form-input
              v-model="confirmPassword"
              class="login-form__input"
              type="password"
              placeholder="Confirm New Password"
            /> -->
          </div>

          <div>
            <button v-if="formValid" class="login-form__button" @click.prevent="submit">Send me recovery instructions</button>
            <button v-else class="login-form__buttondisabled" @click.prevent="">Send me recovery instructions</button>
          </div>
        </form>

      </div>
      <div style="margin-top: 15px;">
                          <a
                class="login-form__link"
                href="/login"
            >Go back to sign in</a></div>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import FormInput from "components/cleverly/FormInput";

export default {
    name: "ResetPassword",
    data() {
        return {
            email: "",
            invitationCode: "",
            password: "",
            confirmPassword: "",
        };
    },
    mounted() {
        if (this.$route.query.invitationCode != undefined)
            this.invitationCode = this.$route.query.invitationCode;
        if (this.$route.query.email != undefined)
            this.email = this.$route.query.email;
    },
    computed: {
        formValid() {
            return /^\S+@\S+$/.test(this.email)
        }
    },
    methods: {
        submit() {
            // Do validation
            if (
                this.email.length == 0
            ) {
                this.toast("Please enter and email address.", "error");
                return;
            }

            this.sendPasswordReset(this.email).then(data => {
                if (!data.success) {
                    return this.toast(
                        "We could not reset your password, please contact support.",
                        "error"
                    );
                }
                return this.$router.push({ name: "reset-password-confirm", params: {email: this.email} });
            });
        },

        ...mapActions("UserStore", ["sendPasswordReset"])
    },

    components: {
        FormInput
    }
};
</script>

<style lang="scss" scoped>
@import "~scss/variables";
@import "~scss/mixins";
.login {
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0 20px;
  margin-top: spacing(xl);
}

.login-form {
  display: flex;
  justify-content: center;

  &__logo-container {
    display: flex;
    justify-content: center;
    margin-bottom: spacing(xxl);
  }

  &__logo {
    width: 228px;
    height: 110px;
    background-image: url("../img/g_hub_white.svg");
    background-size: cover;
  }

  &__form {
    max-width: 500px;
    padding: spacing(xl);
    padding-top: spacing(sm);
    background-color: #fff;
    border-radius: 3px;
  }

  &__title {
    margin-bottom: spacing(sm);
  }
    &__title_bold {
      font-weight: bold;
    margin-bottom: spacing(sm);
  }

  &__input-container {
    margin-bottom: spacing(sm);
  }
  &__button {
    padding: spacing(xxs) spacing(sm);
    margin-bottom: spacing(md);
    background-color: $brand_color;
    color: #fff;
    border: 0;
    border-radius: 3px;
  }

    &__buttondisabled {
    padding: spacing(xxs) spacing(xl);
    margin-bottom: spacing(md);
    background-color: grey;
    color: #fff;
    border: 0;
    border-radius: 3px;
  }

  &__link {
    color: $brand_color;
  }
}
</style>
