var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "login row no-gutters" }, [
    _c("div", { staticClass: "col-md-4" }, [
      _c("div", { staticClass: "login-form row no-gutters" }, [
        _vm._m(0),
        _c("form", { staticClass: "login-form__form shadow--1 col-12" }, [
          _c("div", { staticClass: "login-form__title_bold" }, [
            _vm._v("Reset Password"),
          ]),
          _c("div", { staticClass: "login-form__title" }, [
            _vm._v("Enter the email address associated with your account."),
          ]),
          _c(
            "div",
            { staticClass: "login-form__input-container" },
            [
              _c("form-input", {
                staticClass: "login-form__input",
                attrs: { type: "email", placeholder: "Email" },
                model: {
                  value: _vm.email,
                  callback: function ($$v) {
                    _vm.email = $$v
                  },
                  expression: "email",
                },
              }),
            ],
            1
          ),
          _c("div", [
            _vm.formValid
              ? _c(
                  "button",
                  {
                    staticClass: "login-form__button",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                        return _vm.submit.apply(null, arguments)
                      },
                    },
                  },
                  [_vm._v("Send me recovery instructions")]
                )
              : _c(
                  "button",
                  {
                    staticClass: "login-form__buttondisabled",
                    on: {
                      click: function ($event) {
                        $event.preventDefault()
                      },
                    },
                  },
                  [_vm._v("Send me recovery instructions")]
                ),
          ]),
        ]),
      ]),
      _vm._m(1),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "login-form__logo-container col-12" }, [
      _c("div", { staticClass: "login-form__logo" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticStyle: { "margin-top": "15px" } }, [
      _c("a", { staticClass: "login-form__link", attrs: { href: "/login" } }, [
        _vm._v("Go back to sign in"),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }